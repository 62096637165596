import React, {useEffect} from "react";
import './i18n';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import * as ReactBootstrap from "react-bootstrap";
import {useTranslation} from "react-i18next";

/* Styles */
import './App.css';

/* Icons */
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/js/all.js';

/* Components */
import Header from "./components/header";
import Footer from "./components/footer";

/* Views */
import Start from "./views/start";
import Error from "./views/error";
import Summary from "./views/summary";
import QuestionOne from "./views/questions/questionOne";
import QuestionTwo from "./views/questions/questionTwo";
import QuestionThree from "./views/questions/questionThree";
import QuestionFour from "./views/questions/questionFour";
import QuestionFive from "./views/questions/questionFive";
import QuestionSix from "./views/questions/questionSix";
import QuestionSeven from "./views/questions/questionSeven";
import QuestionEight from "./views/questions/questionEight";
import QuestionNine from "./views/questions/questionNine";
import QuestionTen from "./views/questions/questionTen";
import OverviewSummary from "./views/overviewSummary";

/* node_modules / src*/
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const language = urlParams.get('language') ? urlParams.get('language') : "";

    if(language.toUpperCase() === "DE" || language.toLowerCase() === "dgs"){
      localStorage.setItem("lang", "de");
      i18n.changeLanguage("de")
    }
    else if(language.toUpperCase() === "CH" || language.toLowerCase() === "dsgs"){
      localStorage.setItem("lang", "ch");
      i18n.changeLanguage("ch")
    }
    else if(language.toUpperCase() === "EN" || language.toLowerCase() === "bsl"){
      localStorage.setItem("lang", "en");
      i18n.changeLanguage("en")
    }
    else if(language.toUpperCase() === "FR" || language.toLowerCase() === "lsf"){
      localStorage.setItem("lang", "fr");
      i18n.changeLanguage("fr");
    }
    else if(language.toUpperCase() === "GR" || language.toLowerCase() === "gsl"){
      localStorage.setItem("lang", "gr");
      i18n.changeLanguage("gr");
    }
    else if(language === "" || language === null || language === undefined){
      localStorage.setItem("lang", "en");
      i18n.changeLanguage("en");
    }
    else if(!language && (localStorage.getItem("lang") !== "" || localStorage.getItem("lang") !== null || localStorage.getItem("lang") !== undefined)){
      const languageStorage = localStorage.getItem("lang");
      localStorage.setItem("lang", languageStorage);
      i18n.changeLanguage(languageStorage);
    }
    else {
      localStorage.setItem("lang", "en");
      i18n.changeLanguage("en");
    }
  }, []);

  return (
    <div className="App">
      <Header/>
      <Router>
        <Routes>
          <Route path="/question-ten" element={<QuestionTen />} />
          <Route path="/question-nine" element={<QuestionNine />} />
          <Route path="/question-eight" element={<QuestionEight />} />
          <Route path="/question-seven" element={<QuestionSeven />} />
          <Route path="/question-six" element={<QuestionSix />} />
          <Route path="/question-five" element={<QuestionFive />} />
          <Route path="/question-four" element={<QuestionFour />} />
          <Route path="/question-three" element={<QuestionThree />} />
          <Route path="/question-two" element={<QuestionTwo />} />
          <Route path="/question-one" element={<QuestionOne />} />
          <Route path="/overview-summary" element={<OverviewSummary />} />
          <Route path="/summary" element={<Summary />} />
          <Route path="/" element={<Start />} />
          <Route path="*" element={<Error />}/>
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
