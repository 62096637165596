import React, {useEffect, useState} from "react";
import * as ReactBootstrap from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import IntroductionVideo from './../assets/SUS_DSGS/additional/DSGS_Instruction_new.mp4';
import IntroductionVideoDGS from './../assets/SUS_DGS/DGS_SUS_instruction_new.mp4';
import IntroductionVideoBSL from './../assets/SUS_BSL/SUS_instruction_BSL_new.mp4';
import IntroductionVideoLSF from '../assets/SUS_LSF/Introduction_text/1ere_texte_LSF_V1_new.mp4';
import IntroductionVideoGSL from './../assets/SUS_GSL/GSL_for_app_new.mp4';
import FlagDE from "../assets/icons/flaggerman.png";
import FlagEN from "../assets/icons/flaggreatbritain.png";
import FlagFR from "../assets/icons/flagfrench.png";
import FlagGR from "../assets/icons/flaggreece.png";
import FlagCH from "../assets/icons/flagsuizze.png";

function Start() {
  const [showModal, setShowModal] = useState(true);
  const [userType, setUserType] = useState(1);
  const [professionalType, setProfessionalType] = useState(false);
  const [userTypeSelected, setUserTypeSelected] = useState("1");
  const [checkedProfessional, setCheckedProfessional] = useState(false);

  const { t, i18n } = useTranslation();

  const resetAllOptions = () => {
    localStorage.removeItem("answer-one");
    localStorage.removeItem("question-one");
    localStorage.removeItem("answer-two");
    localStorage.removeItem("question-two");
    localStorage.removeItem("answer-three");
    localStorage.removeItem("question-three");
    localStorage.removeItem("answer-four");
    localStorage.removeItem("question-four");
    localStorage.removeItem("answer-five");
    localStorage.removeItem("question-five");
    localStorage.removeItem("answer-six");
    localStorage.removeItem("question-six");
    localStorage.removeItem("answer-seven");
    localStorage.removeItem("question-seven");
    localStorage.removeItem("answer-eight");
    localStorage.removeItem("question-eight");
    localStorage.removeItem("answer-nine");
    localStorage.removeItem("question-nine");
    localStorage.removeItem("answer-ten");
    localStorage.removeItem("question-ten");
  };

  useEffect(() => {
    setShowModal(true);
    setCheckedProfessional(false);
    localStorage.removeItem("choose-options");
    localStorage.setItem("selectedUserType", "1");
    localStorage.setItem("selectedProfessional", "false");
  }, []);

  const changeLanguageGerman = () =>
  {
    i18n.changeLanguage("de")
    localStorage.setItem("lang", "de")
  }

  const changeLanguageEnglish = () =>
  {
    i18n.changeLanguage("en")
    localStorage.setItem("lang", "en")
  }

  const changeLanguageItalian = () =>
  {
    i18n.changeLanguage("it")
    localStorage.setItem("lang", "it")
  }

  const changeLanguageFrench = () =>
  {
    i18n.changeLanguage("fr")
    localStorage.setItem("lang", "fr")
  }

  const changeLanguageGreek = () =>
  {
    i18n.changeLanguage("gr")
    localStorage.setItem("lang", "gr")
  }

  const changeLanguageDutch = () =>
  {
    i18n.changeLanguage("nl")
    localStorage.setItem("lang", "nl")
  }

  const changeLanguageSwissGerman = () =>
  {
    i18n.changeLanguage("ch")
    localStorage.setItem("lang", "ch")
  }

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem("choose-options", "true");
  };

  const changeUserType = e => {
    setUserTypeSelected(e.target.value);
    localStorage.setItem("selectedUserType", e.target.value);
  }

  const changeProfessional = () => {
    setCheckedProfessional(!checkedProfessional);
    localStorage.setItem("selectedProfessional", String(!checkedProfessional));
  }

  return (
    <div id="easier-start">
      <ReactBootstrap.Container fluid={true}>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="start-form">
              <h2>
                {t('new-questionnaire')}
              </h2>
              <div>
                ({t('anonymous-text')})
              </div>
              <div className={localStorage.getItem("lang") === "ch" ? "question-video is-selected" : "question-video is-not-selected"}>
                <video controls>
                  <source src={IntroductionVideo} type="video/mp4"/>
                </video>
              </div>
              <div className={localStorage.getItem("lang") === "de" ? "question-video is-selected" : "question-video is-not-selected"}>
                <video controls>
                  <source src={IntroductionVideoDGS} type="video/mp4"/>
                </video>
              </div>
              <div className={localStorage.getItem("lang") === "fr" ? "question-video is-selected" : "question-video is-not-selected"}>
                <video controls>
                  <source src={IntroductionVideoLSF} type="video/mp4"/>
                </video>
              </div>
              <div className={localStorage.getItem("lang") === "gr" ? "question-video is-selected" : "question-video is-not-selected"}>
                <video controls>
                  <source src={IntroductionVideoGSL} type="video/mp4"/>
                </video>
              </div>
              <div className={!localStorage.getItem("lang") || localStorage.getItem("lang") === "en" ? "question-video is-selected" : "question-video is-not-selected"}>
                <video controls>
                  <source src={IntroductionVideoBSL} type="video/mp4"/>
                </video>
              </div>
              <div className="introduction-text">
                {t('introduction_text')}
              </div>
              {/*<ReactBootstrap.Dropdown>
                <ReactBootstrap.Dropdown.Toggle variant="danger" id="dropdown-basic">
                  {t('language')}
                </ReactBootstrap.Dropdown.Toggle>
                <ReactBootstrap.Dropdown.Menu>
                  <ReactBootstrap.Dropdown.Item href="#" onClick={changeLanguageEnglish}>
                    {t('English')}
                  </ReactBootstrap.Dropdown.Item>
                  <ReactBootstrap.Dropdown.Item href="#" onClick={changeLanguageGerman}>
                    {t('German')}
                  </ReactBootstrap.Dropdown.Item>
                  <ReactBootstrap.Dropdown.Item href="#" onClick={changeLanguageFrench}>
                    {t('French')}
                  </ReactBootstrap.Dropdown.Item>
                  <ReactBootstrap.Dropdown.Item href="#" onClick={changeLanguageGreek}>
                    {t('Greek')}
                  </ReactBootstrap.Dropdown.Item>
                  <ReactBootstrap.Dropdown.Item href="#" onClick={changeLanguageSwissGerman}>
                    {t('Swiss-German')}
                  </ReactBootstrap.Dropdown.Item>
                </ReactBootstrap.Dropdown.Menu>
              </ReactBootstrap.Dropdown>*/}
              <div className="change-options-start" onClick={openModal}>
                {t('change-options')}
              </div>
              <Link to="/question-one" onClick={resetAllOptions}>
                <div>
                  {t('start')}
                </div>
              </Link>
            </div>
            <ReactBootstrap.Modal
              show={showModal}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <ReactBootstrap.Modal.Header>
                <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter" className="header-title-center">
                  {t('choose-options')}
                </ReactBootstrap.Modal.Title>
              </ReactBootstrap.Modal.Header>
              <ReactBootstrap.Modal.Body id="choose-option-questionnaire">
                <div id="easier-language">
                  <ReactBootstrap.Navbar className="easier-navbar-bg" expand="lg" variant="light">
                    <ReactBootstrap.Container fluid>
                      {/*<ReactBootstrap.Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <ReactBootstrap.Navbar.Collapse id="basic-navbar-nav">
                          <ReactBootstrap.Nav className="justify-content-end" style={{ width: "100%" }}>*/}
                      <ReactBootstrap.NavDropdown title={
                        <>
                          {
                            localStorage.getItem("lang") == "de" ? (
                              <span>
                                <img src={FlagDE} alt="German flag"/>
                              </span>
                            ) : localStorage.getItem("lang") == "en" ? (
                              <span>
                                <img src={FlagEN} alt="English flag"/>
                              </span>
                            ) : localStorage.getItem("lang") == "fr" ? (
                              <span>
                                <img src={FlagFR} alt="French flag"/>
                              </span>
                            ) : localStorage.getItem("lang") == "gr" ? (
                              <span>
                                <img src={FlagGR} alt="Greece flag"/>
                              </span>
                            ) : localStorage.getItem("lang") == "ch" ? (
                              <span>
                                <img src={FlagCH} alt="Swiss flag"/>
                              </span>
                            ) : (
                              <span>
                                <img src={FlagEN} alt="English flag"/>
                              </span>
                            )
                          }
                          {
                            localStorage.getItem("lang") == "de" ? (
                              <>
                                Deutsch/DGS
                              </>
                            ) : localStorage.getItem("lang") == "en" ? (
                              <>
                                English/BSL
                              </>
                            ) : localStorage.getItem("lang") == "fr" ? (
                              <>
                                Français/LSF
                              </>
                            ) : localStorage.getItem("lang") == "gr" ? (
                              <>
                                Ελληνική/GSL
                              </>
                            ) : localStorage.getItem("lang") == "ch" ? (
                              <>
                                Schweizer-Deutsch/DSGS
                              </>
                            ) : (
                              <>
                                English/BSL
                              </>
                            )
                          }
                        </>
                      } id="basic-nav-dropdown-start">
                        <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageEnglish}>
                          <span>
                            <img src={FlagEN} alt="English flag"/>
                          </span>
                          {/*t('English')*/}
                          English/BSL
                        </ReactBootstrap.NavDropdown.Item>
                        <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageGerman}>
                          <span>
                            <img src={FlagDE} alt="German flag"/>
                          </span>
                          {/*t('German')*/}
                          Deutsch/DGS
                        </ReactBootstrap.NavDropdown.Item>
                        <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageFrench}>
                          <span>
                            <img src={FlagFR} alt="French flag"/>
                          </span>
                          {/*t('French')*/}
                          Français/LSF
                        </ReactBootstrap.NavDropdown.Item>
                        {/*<ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageItalian}>
                            {t('Italian')}
                          </ReactBootstrap.NavDropdown.Item>*/}
                        <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageGreek}>
                          <span>
                            <img src={FlagGR} alt="Greece flag"/>
                          </span>
                          {/*t('Greek')*/}
                          Ελληνική/GSL
                        </ReactBootstrap.NavDropdown.Item>
                        {/*<ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageDutch}>
                            {t('Dutch')}
                          </ReactBootstrap.NavDropdown.Item>*/}
                        <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageSwissGerman}>
                          <span>
                            <img src={FlagCH} alt="Swiss flag"/>
                          </span>
                          {/*t('Swiss-German')*/}
                          Schweizer-Deutsch/DSGS
                        </ReactBootstrap.NavDropdown.Item>
                      </ReactBootstrap.NavDropdown>
                      {/*</ReactBootstrap.Nav>
                      </ReactBootstrap.Navbar.Collapse>*/}
                    </ReactBootstrap.Container>
                  </ReactBootstrap.Navbar>
                </div>
                <div className="headline-choice">
                  {t('usertype-headline')}
                </div>
                <div className="choose-usertype">
                  <div>
                    <input type="radio" id="1" value="1" name="usertype" checked={userTypeSelected === "1"} onChange={changeUserType}/>
                    <label htmlFor="1">{t('usertype-one')}</label>
                  </div>
                  <div>
                    <input type="radio" id="2" value="2" name="usertype" checked={userTypeSelected === "2"} onChange={changeUserType}/>
                    <label htmlFor="2">{t('usertype-two')}</label>
                  </div>
                  <div>
                    <input type="radio" id="3" value="3" name="usertype" checked={userTypeSelected === "3"} onChange={changeUserType}/>
                    <label htmlFor="3">{t('usertype-three')}</label>
                  </div>
                  <div>
                    <input type="radio" id="4" value="4" name="usertype" checked={userTypeSelected === "4"} onChange={changeUserType}/>
                    <label htmlFor="4">{t('usertype-four')}</label>
                  </div>
                  <div>
                    <input type="radio" id="5" value="5" name="usertype" checked={userTypeSelected === "5"} onChange={changeUserType}/>
                    <label htmlFor="5">{t('usertype-five')}</label>
                  </div>
                </div>
                <div className="headline-choice">
                  {t('professional-headline')}
                </div>
                <div className="select-professional">
                  <input type="checkbox" id="professional" checked={checkedProfessional} onChange={changeProfessional} className={localStorage.getItem("lang") == "en" ? "additional-distance" : ""}/>
                  <label htmlFor="professional">{t('professional-text')}</label>
                </div>
                <div className="change-options-start" onClick={closeModal}>
                  {t('submit')}
                </div>
              </ReactBootstrap.Modal.Body>
            </ReactBootstrap.Modal>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
      </ReactBootstrap.Container>
    </div>
  );
}

export default Start;
