import React, {useEffect, useState} from "react";
import * as ReactBootstrap from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import axios from "axios";

/* DSGS */
import QuestionTenVideo from './../../assets/SUS_DSGS/additional/DSGS_SUS_Question_10_new.mp4';
import ResponseOne from './../../assets/SUS_DSGS/Response_GIFs/DSGS_Reponse_scale_1_AdobeExpress.gif';
import ResponseTwo from './../../assets/SUS_DSGS/Response_GIFs/DSGS_Reponse_scale_2_AdobeExpress.gif';
import ResponseThree from './../../assets/SUS_DSGS/Response_GIFs/DSGS_Reponse_scale_3_AdobeExpress.gif';
import ResponseFour from './../../assets/SUS_DSGS/Response_GIFs/DSGS_Reponse_scale_4_AdobeExpress.gif';
import ResponseFive from './../../assets/SUS_DSGS/Response_GIFs/DSGS_Reponse_scale_5_AdobeExpress.gif';
import ResponseOneVideo from './../../assets/SUS_DSGS/additional/DSGS_Reponse_scale_1_new.mp4';
import ResponseTwoVideo from './../../assets/SUS_DSGS/additional/DSGS_Reponse_scale_2_new.mp4';
import ResponseThreeVideo from './../../assets/SUS_DSGS/additional/DSGS_Reponse_scale_3_new.mp4';
import ResponseFourVideo from './../../assets/SUS_DSGS/additional/DSGS_Reponse_scale_4_new.mp4';
import ResponseFiveVideo from './../../assets/SUS_DSGS/additional/DSGS_Reponse_scale_5_new.mp4';
/* DGS */
import QuestionTenVideoDGS from './../../assets/SUS_DGS/DGS_SUS_sentence_10_new.mp4';
import ResponseOneDGS from './../../assets/SUS_DGS/Response_GIFs/DGS_SUS_scale_1_AdobeExpress.gif';
import ResponseTwoDGS from './../../assets/SUS_DGS/Response_GIFs/DGS_SUS_scale_2_AdobeExpress.gif';
import ResponseThreeDGS from './../../assets/SUS_DGS/Response_GIFs/DGS_SUS_scale_3_AdobeExpress.gif';
import ResponseFourDGS from './../../assets/SUS_DGS/Response_GIFs/DGS_SUS_scale_4_AdobeExpress.gif';
import ResponseFiveDGS from './../../assets/SUS_DGS/Response_GIFs/DGS_SUS_scale_5_AdobeExpress.gif';
import ResponseOneVideoDGS from './../../assets/SUS_DGS/DGS_SUS_scale_1_new.mp4';
import ResponseTwoVideoDGS from './../../assets/SUS_DGS/DGS_SUS_scale_2_new.mp4';
import ResponseThreeVideoDGS from './../../assets/SUS_DGS/DGS_SUS_scale_3_new.mp4';
import ResponseFourVideoDGS from './../../assets/SUS_DGS/DGS_SUS_scale_4_new.mp4';
import ResponseFiveVideoDGS from './../../assets/SUS_DGS/DGS_SUS_scale_5_new.mp4';
/* BSL */
import QuestionTenVideoBSL from './../../assets/SUS_BSL/Sentence_10_new.mp4';
import ResponseOneBSL from './../../assets/SUS_BSL/Response_GIFs/1_Strongly_Disagree_AdobeExpress.gif';
import ResponseTwoBSL from './../../assets/SUS_BSL/Response_GIFs/2_Somewhat_Disagree_AdobeExpress.gif';
import ResponseThreeBSL from './../../assets/SUS_BSL/Response_GIFs/3_Neutral_AdobeExpress.gif';
import ResponseFourBSL from './../../assets/SUS_BSL/Response_GIFs/4_Somewhat_Agree_AdobeExpress.gif';
import ResponseFiveBSL from './../../assets/SUS_BSL/Response_GIFs/5_Strongly_Agree_AdobeExpress.gif';
import ResponseOneVideoBSL from './../../assets/SUS_BSL/1_Strongly_Disagree_new.mp4';
import ResponseTwoVideoBSL from './../../assets/SUS_BSL/2_Somewhat_Disagree_new.mp4';
import ResponseThreeVideoBSL from './../../assets/SUS_BSL/3_Neutral_new.mp4';
import ResponseFourVideoBSL from './../../assets/SUS_BSL/4_Somewhat_Agree_new.mp4';
import ResponseFiveVideoBSL from './../../assets/SUS_BSL/5_Strongly_Agree_new.mp4';
/* LSF */
import QuestionTenVideoLSF from '../../assets/SUS_LSF/SUS_QUESTION/10_Jaurais_besoin_dapprendre_beaucoup_de_choses_afin_de_mapproprier_ce_systeme_LSF_V1_new.mp4';
import ResponseOneLSF from '../../assets/SUS_LSF/Response_Scale/Response_GIFs/01__Pas_du_tout_daccord_LSF_V1_AdobeExpress.gif';
import ResponseTwoLSF from '../../assets/SUS_LSF/Response_Scale/Response_GIFs/02__Plutot_pas_daccord_LSF_V1_AdobeExpress.gif';
import ResponseThreeLSF from './../../assets/SUS_LSF/Response_Scale/Response_GIFs/03__Neutre_LSF_V1_AdobeExpress.gif';
import ResponseFourLSF from '../../assets/SUS_LSF/Response_Scale/Response_GIFs/04__Plutot_daccord_LSF_V1_AdobeExpress.gif';
import ResponseFiveLSF from '../../assets/SUS_LSF/Response_Scale/Response_GIFs/05__Tout_a_fait_daccord_LSF_V1_AdobeExpress.gif';
import ResponseOneVideoLSF from '../../assets/SUS_LSF/Response_Scale/01_Pas_du_tout_daccord_LSF_V1_new.mp4';
import ResponseTwoVideoLSF from '../../assets/SUS_LSF/Response_Scale/02_Plutot_pas_daccord_LSF_V1_new.mp4';
import ResponseThreeVideoLSF from '../../assets/SUS_LSF/Response_Scale/03_Neutre_LSF_V1_new.mp4';
import ResponseFourVideoLSF from '../../assets/SUS_LSF/Response_Scale/04_Plutot_daccord_LSF_V1_new.mp4';
import ResponseFiveVideoLSF from '../../assets/SUS_LSF/Response_Scale/05_Tout_a_fait_daccord_LSF_V1_new.mp4';
/* GSL */
import QuestionTenVideoGSL from './../../assets/SUS_GSL/SUS10_new.mp4';
import ResponseOneGSL from './../../assets/SUS_GSL/Response_GIFs/Rating1_AdobeExpress.gif';
import ResponseTwoGSL from './../../assets/SUS_GSL/Response_GIFs/Rating2_AdobeExpress.gif';
import ResponseThreeGSL from './../../assets/SUS_GSL/Response_GIFs/Rating3_AdobeExpress.gif';
import ResponseFourGSL from './../../assets/SUS_GSL/Response_GIFs/Rating4_AdobeExpress.gif';
import ResponseFiveGSL from './../../assets/SUS_GSL/Response_GIFs/Rating5_AdobeExpress.gif';
import ResponseOneVideoGSL from './../../assets/SUS_GSL/Rating1_new.mp4';
import ResponseTwoVideoGSL from './../../assets/SUS_GSL/Rating2_new.mp4';
import ResponseThreeVideoGSL from './../../assets/SUS_GSL/Rating3_new.mp4';
import ResponseFourVideoGSL from './../../assets/SUS_GSL/Rating4_new.mp4';
import ResponseFiveVideoGSL from './../../assets/SUS_GSL/Rating5_new.mp4';

function QuestionTen() {
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [language, setLanguage] = useState('');
  const [finalAnswer, setFinalAnswer] = useState(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let languageAvailable = localStorage.getItem("lang");
    let answerAvailable = localStorage.getItem("answer-ten");
    let answerButtons = document.querySelectorAll(".answers > div");

    if(languageAvailable === 'de'){
      setLanguage('de');
    } else if(languageAvailable === 'ch'){
      setLanguage('ch');
    } else if(languageAvailable === 'fr'){
      setLanguage('fr');
    } else if(languageAvailable === 'gr'){
      setLanguage('gr');
    } else {
      setLanguage('en');
    }

    if(answerAvailable){
      answerButtons[answerAvailable-1].classList.add('active-answer');

      setIsButtonActive(true);
    }
  }, []);

  const submittedAnswer = (answerNumber) => {
    localStorage.setItem("answer-ten", answerNumber);
    setFinalAnswer(answerNumber);

    let selectAnswerNumber = (answerNumber - 1);

    let answerButtons = document.querySelectorAll(".answers > div");

    for(let i = 0; i < answerButtons.length; i++){
      if(i == selectAnswerNumber){
        answerButtons[i].classList.add('active-answer');
      } else {
        answerButtons[i].classList.remove('active-answer');
      }
    }

    setIsButtonActive(true);
  };

  const submittedQuestion = () => {
    localStorage.setItem("question-ten", "true");
  };

  const resetSubmittedQuestion = () => {
    localStorage.removeItem("question-ten");
  };

  return (
    <div id="easier-question-ten">
      <ReactBootstrap.Container fluid={true}>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="question-progress-bar">
              <div className="question-progress-bar-percent question-progress-bar-percent-90"></div>
              <div className="question-percent">
                90 %
              </div>
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="question-video">
              <div className={localStorage.getItem("lang") === "ch" ? "question-video is-selected" : "question-video is-not-selected"}>
                <video controls>
                  <source src={QuestionTenVideo} type="video/mp4"/>
                </video>
              </div>
              <div className={localStorage.getItem("lang") === "de" ? "question-video is-selected" : "question-video is-not-selected"}>
                <video controls>
                  <source src={QuestionTenVideoDGS} type="video/mp4"/>
                </video>
              </div>
              <div className={localStorage.getItem("lang") === "fr" ? "question-video is-selected" : "question-video is-not-selected"}>
                <video controls>
                  <source src={QuestionTenVideoLSF} type="video/mp4"/>
                </video>
              </div>
              <div className={localStorage.getItem("lang") === "gr" ? "question-video is-selected" : "question-video is-not-selected"}>
                <video controls>
                  <source src={QuestionTenVideoGSL} type="video/mp4"/>
                </video>
              </div>
              <div className={!localStorage.getItem("lang") || localStorage.getItem("lang") === "en" ? "question-video is-selected" : "question-video is-not-selected"}>
                <video controls>
                  <source src={QuestionTenVideoBSL} type="video/mp4"/>
                </video>
              </div>
            </div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="questions">
              <div>
                10. {t('question-ten')}
              </div>
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="answers">
              <div onClick={() => submittedAnswer(1)}>
                <div className="animation-answer">
                  <div className="static-icon">
                    <div className={localStorage.getItem("lang") === "ch" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseOneVideo} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "de" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseOneVideoDGS} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "fr" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseOneVideoLSF} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "gr" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseOneVideoGSL} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={!localStorage.getItem("lang") || localStorage.getItem("lang") === "en" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseOneVideoBSL} type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                  <div className={localStorage.getItem("lang") === 'ch' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseOne} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'de' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseOneDGS} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'fr' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseOneLSF} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'gr' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseOneGSL} />
                  </div>
                  <div className={!localStorage.getItem("lang") || localStorage.getItem("lang") === 'en' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseOneBSL} />
                  </div>
                </div>
                <span>
                  {t('answer-five')}
                </span>
              </div>
              <div onClick={() => submittedAnswer(2)}>
                <div className="animation-answer">
                  <div className="static-icon">
                    <div className={localStorage.getItem("lang") === "ch" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseTwoVideo} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "de" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseTwoVideoDGS} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "fr" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseTwoVideoLSF} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "gr" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseTwoVideoGSL} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={!localStorage.getItem("lang") || localStorage.getItem("lang") === "en" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseTwoVideoBSL} type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                  <div className={localStorage.getItem("lang") === 'ch' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseTwo} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'de' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseTwoDGS} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'fr' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseTwoLSF} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'gr' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseTwoGSL} />
                  </div>
                  <div className={!localStorage.getItem("lang") || localStorage.getItem("lang") === 'en' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseTwoBSL} />
                  </div>
                </div>
                <span>
                  {t('answer-four')}
                </span>
              </div>
              <div onClick={() => submittedAnswer(3)}>
                <div className="animation-answer">
                  <div className="static-icon">
                    <div className={localStorage.getItem("lang") === "ch" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseThreeVideo} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "de" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseThreeVideoDGS} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "fr" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseThreeVideoLSF} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "gr" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseThreeVideoGSL} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={!localStorage.getItem("lang") || localStorage.getItem("lang") === "en" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseThreeVideoBSL} type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                  <div className={localStorage.getItem("lang") === 'ch' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseThree} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'de' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseThreeDGS} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'fr' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseThreeLSF} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'gr' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseThreeGSL} />
                  </div>
                  <div className={!localStorage.getItem("lang") || localStorage.getItem("lang") === 'en' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseThreeBSL} />
                  </div>
                </div>
                <span>
                  {t('answer-three')}
                </span>
              </div>
              <div onClick={() => submittedAnswer(4)}>
                <div className="animation-answer">
                  <div className="static-icon">
                    <div className={localStorage.getItem("lang") === "ch" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseFourVideo} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "de" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseFourVideoDGS} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "fr" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseFourVideoLSF} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "gr" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseFourVideoGSL} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={!localStorage.getItem("lang") || localStorage.getItem("lang") === "en" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseFourVideoBSL} type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                  <div className={localStorage.getItem("lang") === 'ch' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseFour} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'de' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseFourDGS} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'fr' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseFourLSF} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'gr' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseFourGSL} />
                  </div>
                  <div className={!localStorage.getItem("lang") || localStorage.getItem("lang") === 'en' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseFourBSL} />
                  </div>
                </div>
                <span>
                  {t('answer-two')}
                </span>
              </div>
              <div onClick={() => submittedAnswer(5)}>
                <div className="animation-answer">
                  <div className="static-icon">
                    <div className={localStorage.getItem("lang") === "ch" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseFiveVideo} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "de" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseFiveVideoDGS} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "fr" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseFiveVideoLSF} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={localStorage.getItem("lang") === "gr" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseFiveVideoGSL} type="video/mp4"/>
                      </video>
                    </div>
                    <div className={!localStorage.getItem("lang") || localStorage.getItem("lang") === "en" ? "question-videos is-selected" : "question-videos is-not-selected"}>
                      <video>
                        <source src={ResponseFiveVideoBSL} type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                  <div className={localStorage.getItem("lang") === 'ch' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseFive} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'de' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseFiveDGS} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'fr' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseFiveLSF} />
                  </div>
                  <div className={localStorage.getItem("lang") === 'gr' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseFiveGSL} />
                  </div>
                  <div className={!localStorage.getItem("lang") || localStorage.getItem("lang") === 'en' ? "is-selected" : "is-not-selected"}>
                    <img src={ResponseFiveBSL} />
                  </div>
                </div>
                <span>
                  {t('answer-one')}
                </span>
              </div>
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="buttons">
              <Link to="/question-nine" onClick={resetSubmittedQuestion}>
                <div>
                  <span>
                    <i className="fa-solid fa-hand-point-left"></i>
                    <span>{t('back')}</span>
                  </span>
                </div>
              </Link>
              <Link to="/overview-summary" onClick={submittedQuestion} className={isButtonActive ? "" : "disable-button"}>
                <div>
                  <span>
                    <span>{t('next')}</span>
                    <i className="fa-solid fa-hand-point-right"></i>
                  </span>
                </div>
              </Link>
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
      </ReactBootstrap.Container>
    </div>
  );
}

export default QuestionTen;
