import React, {useEffect, useState} from "react";
import * as ReactBootstrap from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import axios from "axios";

function OverviewSummary() {
  const [userTypeData, setUserTypeData] = useState(1);
  const [professionalData, setProfessionalData] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const userSelectedData = localStorage.getItem("selectedUserType");
    const professionalSelectedData = localStorage.getItem("selectedProfessional");

    if (userSelectedData === "1") {
      setUserTypeData(1);
    } else if (userSelectedData === "2") {
      setUserTypeData(2);
    } else if (userSelectedData === "3") {
      setUserTypeData(3);
    } else if (userSelectedData === "4") {
      setUserTypeData(4);
    } else {
      setUserTypeData(1);
    }

    if (professionalSelectedData === "true") {
      setProfessionalData(true);
    } else {
      setProfessionalData(false);
    }
  });

  const submittedQuestion = (event) => {
    event.preventDefault();

    let languageSelected = localStorage.getItem("lang");
    let answerOne = localStorage.getItem("answer-one");
    let answerTwo = localStorage.getItem("answer-two");
    let answerThree = localStorage.getItem("answer-three");
    let answerFour = localStorage.getItem("answer-four");
    let answerFive = localStorage.getItem("answer-five");
    let answerSix = localStorage.getItem("answer-six");
    let answerSeven = localStorage.getItem("answer-seven");
    let answerEight = localStorage.getItem("answer-eight");
    let answerNine = localStorage.getItem("answer-nine");
    let answerTen = localStorage.getItem("answer-ten");

    axios
      .post("https://backend.easier-integration.nuromedia.com/questionnaire", {
        "language": !languageSelected ? "en" : languageSelected,
        "usertype": Number(userTypeData),
        "professional": professionalData,
        "answer_one": Number(answerOne),
        "answer_two": Number(answerTwo),
        "answer_three": Number(answerThree),
        "answer_four": Number(answerFour),
        "answer_five": Number(answerFive),
        "answer_six": Number(answerSix),
        "answer_seven": Number(answerSeven),
        "answer_eight": Number(answerEight),
        "answer_nine": Number(answerNine),
        "answer_ten": Number(answerTen)
      }, {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then(response => {
        if (response.status === 200) {
          localStorage.setItem("question-ten", "true");

          window.location.href = "/summary";

          const languageStorage = localStorage.getItem("lang");
          localStorage.setItem("lang", languageStorage);
          localStorage.setItem("lang-two", languageStorage);
          i18n.changeLanguage(languageStorage);
        } else {
          console.log("Access denied");
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const resetSubmittedQuestion = () => {
    localStorage.removeItem("question-ten");
  };

  return (
    <div id="easier-overview-summary">
      <ReactBootstrap.Container fluid={true}>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="summary-form">
              <h2>
                {t('summary')}
              </h2>
              <div>
                {t('summary-question')}
              </div>
            </div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="select-button">
              <Link to="/question-ten" onClick={resetSubmittedQuestion}>
                <div>
                <span>
                  <i className="fa-solid fa-hand-point-left"></i>
                  <span>{t('back')}</span>
                </span>
                </div>
              </Link>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="select-button">
              <Link to="/summary" onClick={submittedQuestion}>
                <div>
                <span>
                  <span>{t('submit')}</span>
                  <i className="fa-solid fa-hand-point-right"></i>
                </span>
                </div>
              </Link>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="summary-questions-answers">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                1. {t('question-one')}
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {
                  localStorage.getItem("answer-one") == 1 || localStorage.getItem("answer-one") == "1"
                    ? t('answer-five')
                    : localStorage.getItem("answer-one") == 2 || localStorage.getItem("answer-one") == "2"
                    ? t('answer-four')
                    : localStorage.getItem("answer-one") == 3 || localStorage.getItem("answer-one") == "3"
                      ? t('answer-three')
                      : localStorage.getItem("answer-one") == 4 || localStorage.getItem("answer-one") == "4"
                        ? t('answer-two')
                        : t('answer-one')
                }
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                2. {t('question-two')}
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {
                  localStorage.getItem("answer-two") == 1 || localStorage.getItem("answer-two") == "1"
                    ? t('answer-five')
                    : localStorage.getItem("answer-two") == 2 || localStorage.getItem("answer-two") == "2"
                    ? t('answer-four')
                    : localStorage.getItem("answer-two") == 3 || localStorage.getItem("answer-two") == "3"
                      ? t('answer-three')
                      : localStorage.getItem("answer-two") == 4 || localStorage.getItem("answer-two") == "4"
                        ? t('answer-two')
                        : t('answer-one')
                }
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                3. {t('question-three')}
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {
                  localStorage.getItem("answer-three") == 1 || localStorage.getItem("answer-three") == "1"
                    ? t('answer-five')
                    : localStorage.getItem("answer-three") == 2 || localStorage.getItem("answer-three") == "2"
                    ? t('answer-four')
                    : localStorage.getItem("answer-three") == 3 || localStorage.getItem("answer-three") == "3"
                      ? t('answer-three')
                      : localStorage.getItem("answer-three") == 4 || localStorage.getItem("answer-three") == "4"
                        ? t('answer-two')
                        : t('answer-one')
                }
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                4. {t('question-four')}
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {
                  localStorage.getItem("answer-four") == 1 || localStorage.getItem("answer-four") == "1"
                    ? t('answer-five')
                    : localStorage.getItem("answer-four") == 2 || localStorage.getItem("answer-four") == "2"
                    ? t('answer-four')
                    : localStorage.getItem("answer-four") == 3 || localStorage.getItem("answer-four") == "3"
                      ? t('answer-three')
                      : localStorage.getItem("answer-four") == 4 || localStorage.getItem("answer-four") == "4"
                        ? t('answer-ttwo')
                        : t('answer-one')
                }
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                5. {t('question-five')}
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {
                  localStorage.getItem("answer-five") == 1 || localStorage.getItem("answer-five") == "1"
                    ? t('answer-five')
                    : localStorage.getItem("answer-five") == 2 || localStorage.getItem("answer-five") == "2"
                    ? t('answer-four')
                    : localStorage.getItem("answer-five") == 3 || localStorage.getItem("answer-five") == "3"
                      ? t('answer-three')
                      : localStorage.getItem("answer-five") == 4 || localStorage.getItem("answer-five") == "4"
                        ? t('answer-two')
                        : t('answer-one')
                }
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                6. {t('question-six')}
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {
                  localStorage.getItem("answer-six") == 1 || localStorage.getItem("answer-six") == "1"
                    ? t('answer-five')
                    : localStorage.getItem("answer-six") == 2 || localStorage.getItem("answer-six") == "2"
                    ? t('answer-four')
                    : localStorage.getItem("answer-six") == 3 || localStorage.getItem("answer-six") == "3"
                      ? t('answer-three')
                      : localStorage.getItem("answer-six") == 4 || localStorage.getItem("answer-six") == "4"
                        ? t('answer-two')
                        : t('answer-one')
                }
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                7. {t('question-seven')}
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {
                  localStorage.getItem("answer-seven") == 1 || localStorage.getItem("answer-seven") == "1"
                    ? t('answer-five')
                    : localStorage.getItem("answer-seven") == 2 || localStorage.getItem("answer-seven") == "2"
                    ? t('answer-four')
                    : localStorage.getItem("answer-seven") == 3 || localStorage.getItem("answer-seven") == "3"
                      ? t('answer-three')
                      : localStorage.getItem("answer-seven") == 4 || localStorage.getItem("answer-seven") == "4"
                        ? t('answer-two')
                        : t('answer-one')
                }
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                8. {t('question-eight')}
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {
                  localStorage.getItem("answer-eight") == 1 || localStorage.getItem("answer-eight") == "1"
                    ? t('answer-five')
                    : localStorage.getItem("answer-eight") == 2 || localStorage.getItem("answer-eight") == "2"
                    ? t('answer-four')
                    : localStorage.getItem("answer-eight") == 3 || localStorage.getItem("answer-eight") == "3"
                      ? t('answer-three')
                      : localStorage.getItem("answer-eight") == 4 || localStorage.getItem("answer-eight") == "4"
                        ? t('answer-two')
                        : t('answer-one')
                }
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                9. {t('question-nine')}
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {
                  localStorage.getItem("answer-nine") == 1 || localStorage.getItem("answer-nine") == "1"
                    ? t('answer-five')
                    : localStorage.getItem("answer-nine") == 2 || localStorage.getItem("answer-nine") == "2"
                    ? t('answer-four')
                    : localStorage.getItem("answer-nine") == 3 || localStorage.getItem("answer-nine") == "3"
                      ? t('answer-three')
                      : localStorage.getItem("answer-nine") == 4 || localStorage.getItem("answer-nine") == "4"
                        ? t('answer-two')
                        : t('answer-one')
                }
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                10. {t('question-ten')}
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {
                  localStorage.getItem("answer-ten") == 1 || localStorage.getItem("answer-ten") == "1"
                    ? t('answer-five')
                    : localStorage.getItem("answer-ten") == 2 || localStorage.getItem("answer-ten") == "2"
                    ? t('answer-four')
                    : localStorage.getItem("answer-ten") == 3 || localStorage.getItem("answer-ten") == "3"
                      ? t('answer-three')
                      : localStorage.getItem("answer-ten") == 4 || localStorage.getItem("answer-ten") == "4"
                        ? t('answer-two')
                        : t('answer-one')
                }
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
      </ReactBootstrap.Container>
    </div>
  );
}

export default OverviewSummary;
