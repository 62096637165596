import React, {useEffect, useState} from "react";
import * as ReactBootstrap from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function Summary() {
  const [isSuccessfulSubmitted, setIsSuccessfulSubmitted] = useState(false);
  const { t, i18n } = useTranslation();

  let q1 = localStorage.getItem("question-one");
  let q2 = localStorage.getItem("question-two");
  let q3 = localStorage.getItem("question-three");
  let q4 = localStorage.getItem("question-four");
  let q5 = localStorage.getItem("question-five");
  let q6 = localStorage.getItem("question-six");
  let q7 = localStorage.getItem("question-seven");
  let q8 = localStorage.getItem("question-eight");
  let q9 = localStorage.getItem("question-nine");
  let q10 = localStorage.getItem("question-ten");

  useEffect(() => {
    if (q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q10) {
      setIsSuccessfulSubmitted(true);
    } else {
      setIsSuccessfulSubmitted(false);

      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    }
  }, [q1, q2, q3, q4, q5, q6, q7, q8, q9, q10]);

  useEffect(() => {
    const languageAdditional = localStorage.getItem("lang-two");

    if (languageAdditional) {
      setTimeout(() => {
        localStorage.setItem("lang", languageAdditional);
        i18n.changeLanguage(languageAdditional);
      }, 1000);
    }
  }, []);

  const resetOptions = () => {
    localStorage.removeItem("answer-one");
    localStorage.removeItem("question-one");
    localStorage.removeItem("answer-two");
    localStorage.removeItem("question-two");
    localStorage.removeItem("answer-three");
    localStorage.removeItem("question-three");
    localStorage.removeItem("answer-four");
    localStorage.removeItem("question-four");
    localStorage.removeItem("answer-five");
    localStorage.removeItem("question-five");
    localStorage.removeItem("answer-six");
    localStorage.removeItem("question-six");
    localStorage.removeItem("answer-seven");
    localStorage.removeItem("question-seven");
    localStorage.removeItem("answer-eight");
    localStorage.removeItem("question-eight");
    localStorage.removeItem("answer-nine");
    localStorage.removeItem("question-nine");
    localStorage.removeItem("answer-ten");
    localStorage.removeItem("question-ten");
    localStorage.removeItem("lang-two");
  };

  return (
    <div id="easier-summary">
      <ReactBootstrap.Container fluid={true}>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <div className="summary-form">
              <h2>
                {t('summary')}
              </h2>
              {
                isSuccessfulSubmitted ? (
                  <div>
                    {t('form-submitted-message')}
                  </div>
                ) : (
                  <div>
                    {t('form-submitted-message-error')}
                  </div>
                )
              }
            </div>
          </ReactBootstrap.Col>
          {
            isSuccessfulSubmitted ? (
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="summary-questions-answers">
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    1. {t('question-one')}
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    {
                      localStorage.getItem("answer-one") == 1 || localStorage.getItem("answer-one") == "1"
                        ? t('answer-five')
                        : localStorage.getItem("answer-one") == 2 || localStorage.getItem("answer-one") == "2"
                        ? t('answer-four')
                        : localStorage.getItem("answer-one") == 3 || localStorage.getItem("answer-one") == "3"
                          ? t('answer-three')
                          : localStorage.getItem("answer-one") == 4 || localStorage.getItem("answer-one") == "4"
                            ? t('answer-two')
                            : t('answer-one')
                    }
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    2. {t('question-two')}
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    {
                      localStorage.getItem("answer-two") == 1 || localStorage.getItem("answer-two") == "1"
                        ? t('answer-five')
                        : localStorage.getItem("answer-two") == 2 || localStorage.getItem("answer-two") == "2"
                        ? t('answer-four')
                        : localStorage.getItem("answer-two") == 3 || localStorage.getItem("answer-two") == "3"
                          ? t('answer-three')
                          : localStorage.getItem("answer-two") == 4 || localStorage.getItem("answer-two") == "4"
                            ? t('answer-two')
                            : t('answer-one')
                    }
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    3. {t('question-three')}
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    {
                      localStorage.getItem("answer-three") == 1 || localStorage.getItem("answer-three") == "1"
                        ? t('answer-five')
                        : localStorage.getItem("answer-three") == 2 || localStorage.getItem("answer-three") == "2"
                        ? t('answer-four')
                        : localStorage.getItem("answer-three") == 3 || localStorage.getItem("answer-three") == "3"
                          ? t('answer-three')
                          : localStorage.getItem("answer-three") == 4 || localStorage.getItem("answer-three") == "4"
                            ? t('answer-two')
                            : t('answer-one')
                    }
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    4. {t('question-four')}
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    {
                      localStorage.getItem("answer-four") == 1 || localStorage.getItem("answer-four") == "1"
                        ? t('answer-five')
                        : localStorage.getItem("answer-four") == 2 || localStorage.getItem("answer-four") == "2"
                        ? t('answer-four')
                        : localStorage.getItem("answer-four") == 3 || localStorage.getItem("answer-four") == "3"
                          ? t('answer-three')
                          : localStorage.getItem("answer-four") == 4 || localStorage.getItem("answer-four") == "4"
                            ? t('answer-ttwo')
                            : t('answer-one')
                    }
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    5. {t('question-five')}
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    {
                      localStorage.getItem("answer-five") == 1 || localStorage.getItem("answer-five") == "1"
                        ? t('answer-five')
                        : localStorage.getItem("answer-five") == 2 || localStorage.getItem("answer-five") == "2"
                        ? t('answer-four')
                        : localStorage.getItem("answer-five") == 3 || localStorage.getItem("answer-five") == "3"
                          ? t('answer-three')
                          : localStorage.getItem("answer-five") == 4 || localStorage.getItem("answer-five") == "4"
                            ? t('answer-two')
                            : t('answer-one')
                    }
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    6. {t('question-six')}
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    {
                      localStorage.getItem("answer-six") == 1 || localStorage.getItem("answer-six") == "1"
                        ? t('answer-five')
                        : localStorage.getItem("answer-six") == 2 || localStorage.getItem("answer-six") == "2"
                        ? t('answer-four')
                        : localStorage.getItem("answer-six") == 3 || localStorage.getItem("answer-six") == "3"
                          ? t('answer-three')
                          : localStorage.getItem("answer-six") == 4 || localStorage.getItem("answer-six") == "4"
                            ? t('answer-two')
                            : t('answer-one')
                    }
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    7. {t('question-seven')}
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    {
                      localStorage.getItem("answer-seven") == 1 || localStorage.getItem("answer-seven") == "1"
                        ? t('answer-five')
                        : localStorage.getItem("answer-seven") == 2 || localStorage.getItem("answer-seven") == "2"
                        ? t('answer-four')
                        : localStorage.getItem("answer-seven") == 3 || localStorage.getItem("answer-seven") == "3"
                          ? t('answer-three')
                          : localStorage.getItem("answer-seven") == 4 || localStorage.getItem("answer-seven") == "4"
                            ? t('answer-two')
                            : t('answer-one')
                    }
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    8. {t('question-eight')}
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    {
                      localStorage.getItem("answer-eight") == 1 || localStorage.getItem("answer-eight") == "1"
                        ? t('answer-five')
                        : localStorage.getItem("answer-eight") == 2 || localStorage.getItem("answer-eight") == "2"
                        ? t('answer-four')
                        : localStorage.getItem("answer-eight") == 3 || localStorage.getItem("answer-eight") == "3"
                          ? t('answer-three')
                          : localStorage.getItem("answer-eight") == 4 || localStorage.getItem("answer-eight") == "4"
                            ? t('answer-two')
                            : t('answer-one')
                    }
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    9. {t('question-nine')}
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    {
                      localStorage.getItem("answer-nine") == 1 || localStorage.getItem("answer-nine") == "1"
                        ? t('answer-five')
                        : localStorage.getItem("answer-nine") == 2 || localStorage.getItem("answer-nine") == "2"
                        ? t('answer-four')
                        : localStorage.getItem("answer-nine") == 3 || localStorage.getItem("answer-nine") == "3"
                          ? t('answer-three')
                          : localStorage.getItem("answer-nine") == 4 || localStorage.getItem("answer-nine") == "4"
                            ? t('answer-two')
                            : t('answer-one')
                    }
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    10. {t('question-ten')}
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    {
                      localStorage.getItem("answer-ten") == 1 || localStorage.getItem("answer-ten") == "1"
                        ? t('answer-five')
                        : localStorage.getItem("answer-ten") == 2 || localStorage.getItem("answer-ten") == "2"
                        ? t('answer-four')
                        : localStorage.getItem("answer-ten") == 3 || localStorage.getItem("answer-ten") == "3"
                          ? t('answer-three')
                          : localStorage.getItem("answer-ten") == 4 || localStorage.getItem("answer-ten") == "4"
                            ? t('answer-two')
                            : t('answer-one')
                    }
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </ReactBootstrap.Col>
            ) : (
              <></>
            )
          }
        </ReactBootstrap.Row>
        {
          isSuccessfulSubmitted ? (
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="select-button">
                <Link to="/" onClick={resetOptions}>
                  <div>
                    {t('back-to-start')}
                  </div>
                </Link>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="select-button">
                <Link to="https://easier-integration.nuromedia.com/" onClick={resetOptions}>
                  <div>
                    {t('go-to-main-web-app')}
                  </div>
                </Link>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          ) : (
            <></>
          )
        }
      </ReactBootstrap.Container>
    </div>
  );
}

export default Summary;
