import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import React from "react";

const resources = {
  en: {
    translation: {
      "English": "English",
      "German": "German",
      "French": "French",
      "Italian": "Italian",
      "Greek": "Greek",
      "Dutch": "Dutch",
      "Swiss-Italian": "Swiss-Italian",
      "Swiss-French": "Swiss-French",
      "Swiss-German": "Swiss-German",
      "Luxembourg-German": "Luxembourg-German",
      "Luxembourg-French": "Luxembourg-French",
      "language": "Language",
      "questionnaire": "Usability questionnaire",
      "error-message": "Page not found",
      "back-link": "Back to home",
      "start": "Start",
      "new-questionnaire": "New questionnaire",
      "summary": "Summary",
      "form-submitted-message": "Your questionnaire has been successfully submitted.",
      "form-submitted-message-error": "Your questionnaire could not be sent, please try again or start the questionnaire again.",
      "answer-one": "Strongly agree",
      "answer-two": "Somewhat agree",
      "answer-three": "Neither agree or disagree",
      "answer-four": "Somewhat disagree",
      "answer-five": "Strongly disagree",
      "question-one": "I think that I would like to use this system frequently.",
      "question-two": "I found the system unnecessarily complex.",
      "question-three": "I thought the system was easy to use.",
      "question-four": "I think that I would need the support of a technical person to be able to use this system.",
      "question-five": "I found the various functions in this system were well integrated.",
      "question-six": "I thought there was too much inconsistency in this system.",
      "question-seven": "I would imagine that most people would learn to use this system very quickly.",
      "question-eight": "I found the system very awkward to use.",
      "question-nine": "I felt very confident using the system.",
      "question-ten": "I needed to learn a lot of things before I could get going with this system.",
      "back": "Back",
      "next": "Next",
      "submit": "Submit",
      "introduction_text": "In this survey, you will see 10 sentences. You will read the sentences and decide whether you agree or disagree. The sentences will be related to the app you are testing. First you will play with the app, then answer the questions about what you thought of the app. You will have 5 options: 1) strongly disagree, 2) somewhat disagree 3) neutral 4) somewhat agree and 5) strongly agree. Look at the question, then pick one of the answers.",
      "back-to-start": "New survey",
      "go-to-main-web-app": "Go to EASIER app",
      "summary-question": "Are you sure to send the questionnaire?",
      "change-options": "Change background information",
      "choose-options": "Background information",
      "usertype-headline": "Chose which best describes you:",
      "usertype-one": "Deaf/hard of hearing",
      "usertype-two": "Deaf blind",
      "usertype-three": "Hearing",
      "usertype-four": "Other",
      "usertype-five": "Prefer not to answer",
      "professional-headline": "Do you work in a translation profession (interpreter, translator, etc.)?",
      "professional-text": "Translation professional",
      "anonymous-text": "This questionnaire is anonymous",
    }
  },
  de: {
    translation: {
      "English": "Englisch",
      "German": "Deutsch",
      "French": "Französisch",
      "Italian": "Italienisch",
      "Greek": "Griechisch",
      "Dutch": "Niederländisch",
      "Swiss-Italian": "Schweizer-Italienisch",
      "Swiss-French": "Schweizer-Französisch",
      "Swiss-German": "Schweizer-Deutsch",
      "Luxembourg-German": "Luxemburg-Deutsch",
      "Luxembourg-French": "Luxemburg-Französisch",
      "language": "Sprache",
      "questionnaire": "Fragebogen zur Benutzerfreundlichkeit",
      "error-message": "Seite nicht gefunden",
      "back-link": "Zurück zur Startseite",
      "start": "Start",
      "new-questionnaire": "Neuer Fragebogen",
      "summary": "Zusammenfassung",
      "form-submitted-message": "Ihr Fragebogen wurde erfolgreich abgesendet.",
      "form-submitted-message-error": "Ihr Fragebogen konnte nicht abgesendet werden, bitte versuchen Sie es erneut oder starten den Fragebogen erneut.",
      "answer-one": "Starke Zustimmung",
      "answer-two": "Eher Zustimmung",
      "answer-three": "Weder zustimmen noch ablehnen",
      "answer-four": "Eher Ablehnung",
      "answer-five": "Starke Ablehnung",
      "question-one": "Ich denke, dass ich das System gerne häufig benutzen würde.",
      "question-two": "Ich fand das System unnötig komplex.",
      "question-three": "Ich fand das System einfach zu benutzen.",
      "question-four": "Ich glaube, ich würde die Hilfe einer technisch versierten Person benötigen, um das System benutzen zu können.",
      "question-five": "Ich fand, die verschiedenen Funktionen in diesem System waren gut integriert.",
      "question-six": "Ich denke, das System enthielt zu viele Inkonsistenzen.",
      "question-seven": "Ich kann mir vorstellen, dass die meisten Menschen den Umgang mit diesem System sehr schnell lernen.",
      "question-eight": "Ich fand das System sehr umständlich zu nutzen.",
      "question-nine": "Ich fühlte mich bei der Benutzung des Systems sehr sicher.",
      "question-ten": "Ich musste eine Menge lernen, bevor ich anfangen konnte das System zu verwenden.",
      "back": "Zurück",
      "next": "Weiter",
      "submit": "Absenden",
      "introduction_text": "In dieser Umfrage wirst du 10 Sätze sehen. Du wirst die Sätze lesen und entscheiden, ob du zustimmst oder nicht. Die Sätze beziehen sich auf die App, die du testest. Zuerst spielst du mit der App, dann beantwortest du die Fragen dazu, wie du die App findest. Du hast 5 Möglichkeiten: 1) stimme gar nicht zu, 2) stimme eher nicht zu, 3) neutral, 4) stimme eher zu und 5) stimme voll und ganz zu. Schau dir die Frage an und wähle dann eine der Antworten aus.",
      "back-to-start": "Neue Umfrage",
      "go-to-main-web-app": "Weiter zur EASIER-App",
      "summary-question": "Sind Sie sicher, dass Sie den Fragebogen abschicken wollen?",
      "change-options": "Hintergrundinformationen ändern",
      "choose-options": "Hintergrundinformationen",
      "usertype-headline": "Wählen Sie aus, was Sie am besten beschreibt:",
      "usertype-one": "Gehörlose/Hörgeschädigte",
      "usertype-two": "Taubblind",
      "usertype-three": "Hörend",
      "usertype-four": "Andere",
      "usertype-five": "Möchte nicht antworten",
      "professional-headline": "Sind Sie in einem Übersetzungsberuf tätig (Dolmetscher, Übersetzer usw.)?",
      "professional-text": "Professioneller Übersetzer",
      "anonymous-text": "Dieser Fragebogen ist anonym",
    }
  },
  ch: {
    translation: {
      "English": "Englisch",
      "German": "Deutsch",
      "French": "Französisch",
      "Italian": "Italienisch",
      "Greek": "Griechisch",
      "Dutch": "Niederländisch",
      "Swiss-Italian": "Schweizer-Italienisch",
      "Swiss-French": "Schweizer-Französisch",
      "Swiss-German": "Schweizer-Deutsch",
      "Luxembourg-German": "Luxemburg-Deutsch",
      "Luxembourg-French": "Luxemburg-Französisch",
      "language": "Sprache",
      "questionnaire": "Fragebogen zur Benutzerfreundlichkeit",
      "error-message": "Seite nicht gefunden",
      "back-link": "Zurück zur Startseite",
      "start": "Start",
      "new-questionnaire": "Neuer Fragebogen",
      "summary": "Zusammenfassung",
      "form-submitted-message": "Ihr Fragebogen wurde erfolgreich abgesendet.",
      "form-submitted-message-error": "Ihr Fragebogen konnte nicht abgesendet werden, bitte versuchen Sie es erneut oder starten den Fragebogen erneut.",
      "answer-one": "Starke Zustimmung",
      "answer-two": "Eher Zustimmung",
      "answer-three": "Weder zustimmen noch ablehnen",
      "answer-four": "Eher Ablehnung",
      "answer-five": "Starke Ablehnung",
      "question-one": "Ich denke, dass ich das System gerne häufig benutzen würde.",
      "question-two": "Ich fand das System unnötig komplex.",
      "question-three": "Ich fand das System einfach zu benutzen.",
      "question-four": "Ich glaube, ich würde die Hilfe einer technisch versierten Person benötigen, um das System benutzen zu können.",
      "question-five": "Ich fand, die verschiedenen Funktionen in diesem System waren gut integriert.",
      "question-six": "Ich denke, das System enthielt zu viele Inkonsistenzen.",
      "question-seven": "Ich kann mir vorstellen, dass die meisten Menschen den Umgang mit diesem System sehr schnell lernen.",
      "question-eight": "Ich fand das System sehr umständlich zu nutzen.",
      "question-nine": "Ich fühlte mich bei der Benutzung des Systems sehr sicher.",
      "question-ten": "Ich musste eine Menge lernen, bevor ich anfangen konnte das System zu verwenden.",
      "back": "Zurück",
      "next": "Weiter",
      "submit": "Absenden",
      "introduction_text": "In dieser Umfrage wirst du 10 Sätze sehen. Du wirst die Sätze lesen und entscheiden, ob du zustimmst oder nicht. Die Sätze beziehen sich auf die App, die du testest. Zuerst spielst du mit der App, dann beantwortest du die Fragen dazu, wie du die App findest. Du hast 5 Möglichkeiten: 1) stimme gar nicht zu, 2) stimme eher nicht zu, 3) neutral, 4) stimme eher zu und 5) stimme voll und ganz zu. Schau dir die Frage an und wähle dann eine der Antworten aus.",
      "back-to-start": "Neue Umfrage",
      "go-to-main-web-app": "Weiter zur EASIER-App",
      "summary-question": "Sind Sie sicher, dass Sie den Fragebogen abschicken wollen?",
      "change-options": "Hintergrundinformationen ändern",
      "choose-options": "Hintergrundinformationen",
      "usertype-headline": "Wählen Sie aus, was Sie am besten beschreibt:",
      "usertype-one": "Gehörlose/Hörgeschädigte",
      "usertype-two": "Taubblind",
      "usertype-three": "Hörend",
      "usertype-four": "Andere",
      "usertype-five": "Möchte nicht antworten",
      "professional-headline": "Sind Sie in einem Übersetzungsberuf tätig (Dolmetscher, Übersetzer usw.)?",
      "professional-text": "Professioneller Übersetzer",
      "anonymous-text": "Dieser Fragebogen ist anonym",
    }
  },
  it: {
    translation: {
      "English": "Inglese",
      "German": "Tedesco",
      "French": "Francese",
      "Italian": "Italiano",
      "Greek": "Greco",
      "Dutch": "Olandese",
      "Swiss-Italian": "Svizzero-italiano",
      "Swiss-French": "Svizzero-francese",
      "Swiss-German": "Svizzero-tedesco",
      "Luxembourg-German": "Lussemburgo-tedesco",
      "Luxembourg-French": "Lussemburgo-francese",
      "language": "Lingua",
      "questionnaire": "Questionario di usabilità",
      "error-message": "Pagina non trovata",
      "back-link": "Torna alla home",
      "start": "Inizio",
      "new-questionnaire": "Nuovo questionario",
      "summary": "Sintesi",
      "form-submitted-message": "Il questionario è stato inviato con successo.",
      "form-submitted-message-error": "Non è stato possibile inviare il questionario, si prega di riprovare o di ricominciare il questionario.",
      "answer-one": "Fortemente d'accordo",
      "answer-two": "Abbastanza d'accordo",
      "answer-three": "Né d'accordo né in disaccordo",
      "answer-four": "Abbastanza in disaccordo",
      "answer-five": "Fortemente in disaccordo",
      "question-one": "Penso che mi piacerebbe usare questo sistema di frequente.",
      "question-two": "Ho trovato il sistema inutilmente complesso.",
      "question-three": "Penso che il sistema sia facile da usare.",
      "question-four": "Penso che avrei bisogno del supporto di un tecnico per poter utilizzare questo sistema.",
      "question-five": "Trovo che le varie funzioni del sistema siano ben integrate.",
      "question-six": "Ritengo che ci sia troppa incoerenza nel sistema.",
      "question-seven": "Immagino che la maggior parte delle persone imparerebbe a usare questo sistema molto rapidamente.",
      "question-eight": "Ho trovato il sistema molto scomodo da usare.",
      "question-nine": "Mi sono sentito molto sicuro nell'utilizzo del sistema.",
      "question-ten": "Ho dovuto imparare molte cose prima di poter utilizzare questo sistema.",
      "back": "Indietro",
      "next": "Avanti",
      "submit": "Invia",
      "introduction_text": "In questo sondaggio vedrete 10 frasi. Dovrete leggere le frasi e decidere se siete d'accordo o meno. Le frasi saranno relative all'applicazione che state testando. Prima giocherete con l'app, poi risponderete alle domande su cosa ne pensate. Avrete 5 opzioni: 1) fortemente in disaccordo, 2) leggermente in disaccordo 3) neutro 4) leggermente d'accordo e 5) fortemente d'accordo. Guardate la domanda e scegliete una delle risposte.",
      "back-to-start": "Nuovo sondaggio",
      "go-to-main-web-app": "Vai all'applicazione EASIER",
      "summary-question": "Sei sicuro di voler inviare il questionario?",
      "change-options": "Modifica delle informazioni di base",
      "choose-options": "Informazioni di sfondo",
      "usertype-headline": "Scegliete quello che vi descrive meglio:",
      "usertype-one": "Sordi/non udenti",
      "usertype-two": "Sordo cieco",
      "usertype-three": "Udenteo",
      "usertype-four": "Altro",
      "usertype-five": "Preferisco non risponderer",
      "professional-headline": "Svolge una professione di traduzione (interprete, traduttore, ecc.)?",
      "professional-text": "Professionista della traduzione",
      "anonymous-text": "Il questionario è anonimo",
    }
  },
  fr: {
    translation: {
      "English": "Anglais",
      "German": "Allemand",
      "French": "Français",
      "Italian": "Italienne",
      "Greek": "Grec",
      "Dutch": "Néerlandais",
      "Swiss-Italian": "Suisse-Italienne",
      "Swiss-French": "Suisse-Français",
      "Swiss-German": "Suisse-Allemand",
      "Luxembourg-German": "Luxembourg-Allemand",
      "Luxembourg-French": "Luxembourg-Français",
      "language": "Langue",
      "questionnaire": "Questionnaire d'utilisabilité",
      "error-message": "Page non trouvée",
      "back-link": "Retour à l'accueil",
      "start": "Démarrage",
      "new-questionnaire": "Nouveau questionnaire",
      "summary": "Résumé",
      "form-submitted-message": "Votre questionnaire a été envoyé avec succès.",
      "form-submitted-message-error": "Votre questionnaire n'a pas pu être envoyé, veuillez réessayer ou recommencer le questionnaire.",
      "answer-one": "Tout à fait d'accord",
      "answer-two": "Plutôt d'accord",
      "answer-three": "Ni d'accord ni en désaccord",
      "answer-four": "Plutôt en désaccord",
      "answer-five": "Pas du tout d'accord",
      "question-one": "Je pense que j’aimerais utiliser ce système fréquemment.",
      "question-two": "J’ai trouvé que ce système était inutilement complexe.",
      "question-three": "Je pense que ce système est facile à utiliser.",
      "question-four": "Je pense que j’aurais besoin de l’aide d’un expert technique pour être capable d’utiliser ce système.",
      "question-five": "J’ai trouvé que les diverses fonctions étaient bien intégrées dans le système.",
      "question-six": "J’ai trouvé que ce système comportait trop d’incohérence",
      "question-seven": "J’imagine que la plupart des personnes pourraient apprendre à utiliser ce système très rapidement.",
      "question-eight": "J’ai trouvé le système très difficile à utiliser.",
      "question-nine": "Je me suis senti très à l’aise pour utiliser ce système.",
      "question-ten": "J’aurais besoin d’apprendre beaucoup de choses afin de m’approprier ce système.",
      "back": "Retour",
      "next": "Suivant",
      "submit": "Soumettre",
      "introduction_text": "Dans ce questionnaire vous verrez 10 phrases. Pour chaque phrase, vous allez devoir dire si vous êtes d’accord ou non. Ces phrases sont en lien avec l’application que vous allez tester. Vous commencerez par vous amuser avec l’application puis vous répondrez aux questions pour dire ce que vous en avez pensé. Vous aurez 5 réponses possibles: 1) Pas du tout d’accord, 2) Plutôt pas d’accord, 3) Neutre, 4) Plutôt d’accord, 5) Tout à fait d’accord. Regardez les questions et choisissez l’une des réponses.",
      "back-to-start": "Nouvelle enquête",
      "go-to-main-web-app": "Aller à l'application EASIER",
      "summary-question": "Êtes-vous sûr d'envoyer le questionnaire?",
      "change-options": "Modifier les informations de base",
      "choose-options": "Informations d'arrière-plan",
      "usertype-headline": "Choisissez ce qui vous décrit le mieux :",
      "usertype-one": "Sourds/malentendants",
      "usertype-two": "Sourd aveugle",
      "usertype-three": "Entendant",
      "usertype-four": "Autre",
      "usertype-five": "Préfère ne pas répondre",
      "professional-headline": "Exercez-vous une profession de traduction (interprète, traducteur, etc.) ?",
      "professional-text": "Professionnel de la traduction",
      "anonymous-text": "Ce questionnaire est anonyme",
    }
  },
  gr: {
    translation: {
      "English": "Αγγλικά",
      "German": "Γερμανικά",
      "French": "Γαλλικά",
      "Italian": "Ιταλικά",
      "Greek": "Ελληνική",
      "Dutch": "Ολλανδικά",
      "Swiss-Italian": "Ελβετικό-ιταλικό",
      "Swiss-French": "Ελβετικό-Γαλλικά",
      "Swiss-German": "Ελβετικό-Γερμανικά",
      "Luxembourg-German": "Λουξεμβούργο-Γερμανικά",
      "Luxembourg-French": "Λουξεμβούργο-Γαλλικά",
      "language": "Γλώσσα",
      "questionnaire": "Ερωτηματολόγιο ευχρηστίας",
      "error-message": "Η σελίδα δεν βρέθηκε",
      "back-link": "Επιστροφή στην αρχική σελίδα",
      "start": "Έναρξη",
      "new-questionnaire": "Νέο ερωτηματολόγιο",
      "summary": "Περίληψη",
      "form-submitted-message": "Το ερωτηματολόγιό σας υποβλήθηκε με επιτυχία.",
      "form-submitted-message-error": "Το ερωτηματολόγιό σας δεν μπόρεσε να αποσταλεί, παρακαλούμε προσπαθήστε ξανά ή ξεκινήστε το ερωτηματολόγιο από την αρχή.",
      "answer-one": "Συμφωνώ απόλυτα",
      "answer-two": "Συμφωνώ κάπως",
      "answer-three": "Ούτε συμφωνώ ούτε διαφωνώ",
      "answer-four": "Διαφωνώ κάπως",
      "answer-five": "Διαφωνώ απόλυτα",
      "question-one": "Νομίζω ότι θα ήθελα να χρησιμοποιώ αυτό το σύστημα συχνά",
      "question-two": "Βρήκα το σύστημα ανεξήγητα περίπλοκο",
      "question-three": "Πιστεύω ότι το σύστημα είναι πολύ εύκολο στη χρήση",
      "question-four": "Νομίζω ότι θα ήθελα βοήθεια από κάποιον/α με τεχνικές γνώσεις για να χρησιμοποιήσω αυτό το σύστημα",
      "question-five": "Βρήκα τις διάφορες λειτουργίες του συστήματος ορθά ολοκληρωμένες ",
      "question-six": "Πιστεύω ότι στο συγκεκριμένο σύστημα υπάρχουν πολλές ασυνέπειες",
      "question-seven": "Φαντάζομαι ότι η πλειοψηφία των ανθρώπων θα μάθει να χρησιμοποιεί το συγκεκριμένο σύστημα πολύ γρήγορα",
      "question-eight": "Βρήκα το σύστημα πολύ περίπλοκο ως προς τη χρήση του",
      "question-nine": "Ένοιωσα πολύ σίγουρος/η χρησιμοποιώντας αυτό το σύστημα",
      "question-ten": "Χρειάστηκε να μάθω πολλά πράγματα πριν καταφέρω να ξεκινήσω με αυτό το σύστημα",
      "back": "Πίσω",
      "next": "Επόμενο",
      "submit": "Υποβολή",
      "introduction_text": "Στο πλαίσιο αυτής της έρευνας θα δεις 10 προτάσεις. Θα διαβάσεις τις προτάσεις και θα αποφασίσεις αν συμφωνείς ή διαφωνείς. Οι προτάσεις έχουν σχέση με την εφαρμογή που αξιολογείς. Πρώτα θα παίξεις με την εφαρμογή, και μετά θα απαντήσεις ανάλογα με το πώς βρήκες την εφαρμογή. Για κάθε πρόταση θα έχεις 5 εναλλακτικές απαντήσεις: 1) Διαφωνώ κάθετα, 2) Ελαφρώς διαφωνώ, 3) Μου είναι ουδέτερο, 4) Γενικά συμφωνώ, 5) Συμφωνώ απόλυτα. Διάβασε κάθε πρόταση και στη συνέχεια διάλεξε μια από τις παραπάνω 5 απαντήσεις.",
      "back-to-start": "Νέα έρευνα",
      "go-to-main-web-app": "Μετάβαση στην εφαρμογή EASIER",
      "summary-question": "Σίγουρα θα στείλετε το ερωτηματολόγιο;",
      "change-options": "Αλλαγή πληροφοριών υποβάθρου",
      "choose-options": "Πληροφορίες υποβάθρου",
      "usertype-headline": "Επιλέξτε αυτό που σας περιγράφει καλύτερα:",
      "usertype-one": "Κωφοί/βαρήκοοι",
      "usertype-two": "Κωφός τυφλός",
      "usertype-three": "Ακούοντες",
      "usertype-four": "Άλλο",
      "usertype-five": "Προτιμήστε να μην απαντήσετε",
      "professional-headline": "Εργάζεστε σε μεταφραστικό επάγγελμα (διερμηνέας, μεταφραστής κ.λπ.);",
      "professional-text": "Επαγγελματίας μεταφραστής",
      "anonymous-text": "Αυτό το ερωτηματολόγιο είναι ανώνυμο",
    }
  },
  nl: {
    translation: {
      "English": "Engels",
      "German": "Duits",
      "French": "Frans",
      "Italian": "Italiaans",
      "Greek": "Grieks",
      "Dutch": "Nederlands",
      "Swiss-Italian": "Zwitsers-Italiaans",
      "Swiss-French": "Zwitsers-Frans",
      "Swiss-German": "Zwitsers-Duits",
      "Luxembourg-German": "Luxemburg-Duits",
      "Luxembourg-French": "Luxemburg-Frans",
      "language": "Taal",
      "questionnaire": "Bruikbaarheidsvragenlijst",
      "error-message": "Pagina niet gevonden",
      "back-link": "Terug naar home",
      "start": "Start",
      "new-questionnaire": "Nieuwe vragenlijst",
      "summary": "Samenvatting",
      "form-submitted-message": "Uw vragenlijst is succesvol verzonden.",
      "form-submitted-message-error": "Uw vragenlijst kon niet worden verzonden, probeer het opnieuw of start de vragenlijst opnieuw.",
      "answer-one": "Zeer mee eens",
      "answer-two": "Enigszins mee eens",
      "answer-three": "Noch mee eens, noch mee oneens",
      "answer-four": "Enigszins mee oneens",
      "answer-five": "Zeer mee oneens",
      "question-one": "Ik denk dat ik dit systeem vaak zou willen gebruiken.",
      "question-two": "Ik vond het systeem onnodig complex.",
      "question-three": "Ik vond het systeem gemakkelijk te gebruiken.",
      "question-four": "Ik denk dat ik de ondersteuning van een technisch persoon nodig zou hebben om dit systeem te kunnen gebruiken.",
      "question-five": "Ik vond de verschillende functies in dit systeem goed geïntegreerd.",
      "question-six": "Ik vond dat er te veel inconsistentie in dit systeem zat.",
      "question-seven": "Ik kan me voorstellen dat de meeste mensen dit systeem snel zouden leren gebruiken.",
      "question-eight": "Ik vond het systeem erg onhandig in gebruik.",
      "question-nine": "Ik voelde me erg vertrouwd met het systeem.",
      "question-ten": "Ik moest veel dingen leren voordat ik met dit systeem aan de slag kon.",
      "back": "Terug",
      "next": "Volgende",
      "submit": "Aanmelden",
      "introduction_text": "In deze enquête zie je 10 zinnen. Je moet de zinnen lezen en beslissen of je het ermee eens of oneens bent. De zinnen hebben betrekking op de app die je aan het testen bent. Eerst speel je met de app en daarna beantwoord je de vragen over wat je van de app vond. Je hebt 5 opties: 1) helemaal mee oneens, 2) enigszins mee oneens 3) neutraal 4) enigszins mee eens en 5) helemaal mee eens. Kijk naar de vraag en kies een van de antwoorden.",
      "back-to-start": "Nieuw onderzoek",
      "go-to-main-web-app": "Ga naar de EASIER-app",
      "summary-question": "Weet je zeker dat je de vragenlijst moet versturen?",
      "change-options": "Achtergrondinformatie wijzigen",
      "choose-options": "Achtergrondinformatie",
      "usertype-headline": "Kies wat jou het beste beschrijft:",
      "usertype-one": "Doof/slechthorend",
      "usertype-two": "Doof blind",
      "usertype-three": "Horend",
      "usertype-four": "Andere",
      "usertype-five": "Liever geen antwoord",
      "professional-headline": "Werkt u in een vertaalberoep (tolk, vertaler, etc.)?",
      "professional-text": "Vertaler",
    }
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("lang") || "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
